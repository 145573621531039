.container {
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.container::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.note-preview-title-row {
  display: grid;
  grid-template-columns: auto min-content;
}

.note-preview-title-row .note-preview-title {
  grid-row: 1;
  margin-top: auto;
  margin-bottom: auto;
  overflow: hidden;
}

.note-preview-delete-icon {
  grid-row: 1;
}

.root {
  flex-grow: 1;
  height: 100%;
  max-height: inherit;
  grid-row-start: 2;
  grid-column-start: 1;
}

.NoteEditor {
  flex-grow: 1;
  max-height: inherit;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
}

.NoteEditor.skeleton {
  transform: scale(1, 0.9);
}

@media (max-width: 959px) {
  .NoteEditor {
    height: inherit;
    box-shadow: unset !important;
  }
}

@media (min-width: 960px) {
  .NoteEditor {
    border-radius: 2em !important;
  }
}

.NotesFields {
  display: flex;
  min-width: 0;
  max-height: inherit;
  min-height: 0;
  flex-direction: column;
  flex-grow: 1;
  padding: 5% 5% 0% 5%;
}

button.button {
  border-radius: 2rem;
  z-index: 1000;
  grid-row-start: 2;
  grid-column-start: 1;
  height: 3rem;
  align-self: end;
  justify-self: right;
  width: max-content;
  background: var(--secondary);
}

button.icon.button {
  background: unset;
}

.TextField {
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.TextField::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.resizeTitle {
  font-size: 2.5rem !important;
}

.resizeSubheader {
  font-size: 1.5rem !important;
}

.noteAction .save-button {
  float: right;
}

.noteAction * {
  flex: 1;
}

.notesList {
  flex-grow: 1;
  max-height: inherit;
  display: grid;
  grid-template-rows: min-content;
  min-height: 0;
  padding: 1%;
  height: inherit;
}

.notesList .container {
  max-height: inherit;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 1rem;
  grid-row-start: 2;
  grid-column-start: 1;
  overflow-y: scroll;
  padding: 1%;
}

.notesList .container .note {
  grid-row: auto;
  grid-column: auto;
  padding: 7%;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  height: 250px;
}

.notesList .container .note :last-child {
  overflow: hidden;
  overflow-wrap: break-word;
  flex-grow: 1;
}

.notesRoot {
  flex-grow: 1;
  max-height: inherit;
  height: inherit;
  display: flex;
  flex-direction: column;
}

.waypoint {
  grid-column: 1 / -1;
  height: 1px;
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeLarge {
  font-size: 3.125rem;
}

.loaderContainer {
  align-items: center;
  display: flex;
  height: 100%;
  text-align: center;
  /* display: grid; */
  justify-content: center;
  flex-direction: column;
}

.loader {
  width: 49%;
  border-radius: 1rem;
}

.MuiDialog-paper.dialog-paper {
  height: 100%;
}

.MuiDialog-paper.dialog-paper.border-radius {
  border-radius: 2rem;
}

.loaderContainer .loaderMsg {
  margin: 1%;
}

/* *::webkit-scrollbar {
  width: 20px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 1rem;
  border: 6px solid transparent;
  background-clip: content-box;
} */
