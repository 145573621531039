.home {
  height: inherit;
  /* background: linear-gradient(45deg, #ff1aa3 15%, #fc6767 85%); */
  background: var(--secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  color: #fff;
}

.home .author {
  margin-bottom: 1rem;
}

.home :not(:last-child) {
  margin-right: 2.5%;
}

.home .homebutton {
  color: white;
  border: 3px solid white;
  font-weight: bold;
}
